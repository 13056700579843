@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.App {
  text-align: center;
}

.body {
  font-family: "Lato", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input#steps-range::-webkit-slider-thumb {
  background: rgb(71, 51, 160);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-1wvake5 {
  position: relative;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #1a1a1a !important;
  -webkit-transition: width, left, right, 300ms;
  transition: width, left, right, 300ms;
  width: 250px;
  min-width: 170px !important;
}

.tab-lists {
  .react-tabs__tab--selected {
    background: lightgray !important;
    border-color: black !important;
    border: 5px solid black !important;
    font-family: monospace;
    color: black;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
  }
}

.react-tabs__tab--selected {
  background: lightgray !important;
  border-color: #ff5757 !important;
  border: 5px solid #ff5757 !important;
  font-family: monospace;
  color: black;
  border-radius: 5px 5px 0 0;
}

.css-1wvake5.ps-collapsed {
  width: 80px;
  min-width: 50px !important;
}

.dougnut-chart {
  width: 200px !important;
  height: 200px !important;
}

.bar-chart {
  width: 220px !important;
  height: 160px !important;
}

.scatter-chart {
  width: 220px !important;
  height: 160px !important;
}

.line-chart {
  width: 220px !important;
  height: 160px !important;
}

/* span.semicircle-percent-value {
  width: 40% !important;
} */

.react-dot-tab {
  background: transparent !important;
  border: none !important;
}

.react-dot-tab:focus {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

canvas.scatter-charts {
  width: 100% !important;
}

.baddie-logo {
  width: 130px;
  object-fit: contain;
}

.b-logo {
  color: red;
  font-size: 50px;
  font-weight: 600;
}

.carlos-tab {
  background: lightgray !important;
  color: #000 !important;
  border-radius: 10px !important;
  border: 2px solid #000;
}

.page-title {
  font-size: 30px;
  /* text-transform: lowercase; */
}

.page-desc {
  font-size: 24px;
  text-transform: lowercase;
}

.research-text {
  font-size: 32px;
}

.tool-text {
  font-size: 36px;
  letter-spacing: 5px;
}

.player-list {
  border: 3px solid rgb(194, 192, 192);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.example::-webkit-scrollbar {
  display: none;
}

.buttonPlayer {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}

.image-1 {
  width: 50%;
  margin: auto;
}

.image-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ev-text {
  font-size: 21px;
  font-weight: 500;
  margin-top: 18px;
}

.range-slider .range-slider__thumb {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #b72ec9 !important;
}

.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  background: #b72ec9 !important;
}

.sidebar-main {
  display: flex;
}

.sidebar-mobile {
  display: none;
}

.earning-card::-webkit-scrollbar {
  display: none;
}

.mobile {
  display: none;
}

.game-temp {
  font-size: 50px;
}

@media screen and (max-width: 1024px) {
  .bar-chart {
    width: 170px !important;
    height: 130px !important;
  }

  .pitcher-text {
    font-size: 14px;
  }

  .dougnut-chart {
    width: 160px !important;
    height: 160px !important;
  }

  .scatter-chart {
    width: 175px !important;
    height: 131px !important;
  }

  .line-chart {
    width: 170px !important;
    height: 130px !important;
  }

  .pitcher-title {
    font-size: 22px;
    font-weight: 600;
  }

  .pitcher-desc {
    font-size: 18px;
  }

  .segment-info {
    padding: 7px 50px;
    letter-spacing: 3px;
  }

  .difference {
    font-size: 20px;
    padding: 10px;
  }

  .segment-data {
    width: 180px;
  }

  canvas.scatter-charts {
    width: 100% !important;
  }

  .main-section {
    padding: 10px 20px;
  }

  .player-list {
    width: 260px;
    font-size: 18px;
    text-align: center;
  }

  .greater {
    font-size: 18px;
  }

  .progress-bars {
    width: 250px;
  }

  .ev-text {
    font-size: 18px;
  }

  .main-heading {
    font-size: 3.5rem;
  }

  .main-box {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .main-box {
    height: 180px;
  }

  .baddie-text {
    text-align: center;
    font-size: 14px;
  }

  .zaddie-text {
    font-size: 20px;
  }

  .pitcher-text {
    font-size: 14px;
  }

  .bar-chart {
    width: 220px !important;
    height: 150px !important;
  }

  .grid.sm\:grid-cols-1.chart-section.md\:grid-cols-2.gap-6.mt-5 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .dougnut-chart {
    width: 200px !important;
    height: 200px !important;
  }

  .scatter-chart {
    width: 210px !important;
    height: 150px !important;
  }

  .line-chart {
    width: 210px !important;
    height: 150px !important;
  }

  .pitcher-title {
    font-size: 18px;
    font-weight: 600;
  }

  .pitcher-desc {
    font-size: 14px;
  }

  .team-over {
    font-size: 18px;
  }

  .picks-text {
    font-size: 20px;
  }

  .pitcher-tools {
    font-size: 20px;
  }

  .segment {
    font-size: 20px;
  }

  .segment-desc {
    font-size: 16px;
  }

  .segment-info {
    padding: 5px 20px;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .difference {
    font-size: 20px;
    padding: 10px;
  }

  .segment-data {
    width: 160px;
  }

  canvas.scatter-charts {
    width: 100% !important;
  }

  .player-list {
    width: 150px;
    font-size: 18px;
  }

  .field-text {
    font-size: 15px;
    line-height: normal;
  }

  .greater {
    font-size: 14px;
  }

  .progress-bars {
    width: 150px;
  }

  /* .player-flex {
    display: block;
  } */
  .ev-text {
    font-size: 16px;
  }

  .player-select {
    width: 16rem;
  }

  .game-box {
    padding: 20px 10px;
  }

  .game-text {
    font-size: 22px;
  }

  .team-text {
    font-size: 30px;
  }

  .game-titles {
    font-size: 35px;
    white-space: nowrap;
  }

  .game-table-head th {
    font-size: 16px;
    line-height: normal;
  }

  .game-table-body td {
    white-space: nowrap;
    font-size: 16px;
  }

  .main-heading {
    font-size: 3rem;
  }

  .no-data {
    font-size: 26px;
  }

  .table-header th {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
  }

  .bullpen-title {
    font-size: 34px;
  }

  .bullpen-data {
    font-size: 30px;
  }

  .work-data {
    font-size: 14px;
    white-space: nowrap;
  }

  .workload-data td {
    font-size: 14px;
  }

  .table-outer-section {
    max-width: 100vw;
  }

  .team-defense h3 {
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
  }

  .team-defence {
    margin-bottom: 30px;
  }

  .bullpen-cards {
    padding-left: 0px;
    padding-right: 0px;
  }

  .weather-cards {
    padding-left: 0px;
    padding-right: 0px;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 760px) {
  .sidebar-mobile {
    display: block;
  }

  .mobile-side {
    width: 100%;
    height: 100vh;
  }

  .home-mobile {
    padding: 10px;
    margin-top: 50px;
  }

  .sidebar-outer {
    display: none;
  }

  /* .mobile-inner {
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */
  .mobile-inner::-webkit-scrollbar {
    display: none;
  }

  .sidebar-main {
    display: block;
  }

  .login-sign {
    width: 60px;
    font-size: 16px;
    padding: 1px 8px;
  }

  .player-select {
    width: 100%;
  }

  .player-flex {
    display: block;
  }

  .user-section {
    width: 60px;
    font-size: 13px;
    line-height: normal;
  }

  .hits-text {
    font-size: 12px;
    line-height: normal;
  }

  .hits-value {
    font-size: 16px;
    line-height: normal;
  }

  .hits-section {
    height: 80px;
  }

  .selector-mobile {
    width: 300px;
    font-size: 18px;
    margin-bottom: 40px;
  }

  .counter-mobile {
    margin-bottom: 60px;
  }

  .promo-code {
    font-size: 14px;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 540px) {
  .game-upper-section {
    display: block;
  }

  .game-section {
    width: 100%;
  }

  .game-text {
    font-size: 30px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .game-titles {
    font-size: 20px;
  }

  .mobile-head th {
    font-size: 12px;
  }

  .mobile-body td {
    font-size: 14px;
    text-align: center;
  }

  .carlos-tab {
    font-size: 20px;
    line-height: normal;
  }

  .mobile-tab {
    font-size: 16px;
    width: 20%;
  }

  .green-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  .no-data-text {
    font-size: 16px;
  }

  .graph_box {
    display: block;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

.mobile-team-defense {
  display: none;
}

@media screen and (max-width: 480px) {
  .bullpen-stats {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop-team-defense {
    display: none;
  }

  .mobile-team-defense {
    display: block;
  }

  .team-defence {
    font-size: 32px;
  }

  .team-defense-section {
    padding: 20px 0px;
  }

  .outer-section {
    padding: 5px;
  }

  .team-defense-section {
    border-radius: 70px;
  }

  .main-section {
    padding-left: 5px;
    padding-right: 5px;
  }

  .chance-precip {
    text-align: center;
    line-height: 1.5;
  }

  .card-sec {
    height: 170px;
  }

  .chance-texts {
    display: block;
  }

  .graph_box {
    display: block;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
  /* .first-section {
    width: 25%;
  }
  .medium-sec {
    width: 40%;
  } */
}

@media screen and (max-width: 430px) {
  .graph_box {
    display: block;
  }

  .bullpen-stats {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-section {
    padding: 10px 0px;
  }

  .chance-precip {
    text-align: center;
    line-height: 1.5;
  }

  .bullpen-title {
    font-size: 20px;
  }

  .bullpen-data {
    font-size: 16px;
    white-space: nowrap;
  }

  .image-1 {
    width: 100%;
  }

  .slate-box {
    padding: 10px;
  }

  .slate-box h1 {
    margin-top: 0px;
  }

  .baddie-text-logo {
    font-size: 20px;
  }

  .player-list {
    width: 200px;
    font-size: 18px;
  }

  .graph-section {
    width: 90%;
    margin: auto;
  }

  .graph-display {
    display: block;
  }

  /* .image-section {
    height: 350px;
  } */
  .baddie-text-logo2 {
    font-size: 35px;
  }

  .grid.grid-cols-2.gap-4.md\:h-52.chart-card.sm\:h-auto.items-center.px-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .bar-chart {
    width: 190px !important;
    height: 140px !important;
  }

  .dougnut-chart {
    width: 180px !important;
    height: 180px !important;
  }

  .scatter-chart {
    width: 190px !important;
    height: 140px !important;
  }

  .line-chart {
    width: 190px !important;
    height: 140px !important;
  }

  .pitcher-title {
    font-size: 16px;
    font-weight: 600;
  }

  .pitcher-desc {
    font-size: 14px;
  }

  .team-over {
    font-size: 16px;
    line-height: normal;
  }

  .picks-text {
    font-size: 18px;
  }

  .pitcher-tools {
    font-size: 18px;
  }

  .segment {
    font-size: 18px;
    line-height: normal;
  }

  .segment-desc {
    font-size: 14px;
    line-height: normal;
  }

  .segment-info {
    padding: 5px 10px;
    letter-spacing: 0px;
    line-height: normal;
    font-size: 12px;
  }

  .difference {
    font-size: 16px;
    padding: 10px;
    letter-spacing: 0px;
    line-height: normal;
  }

  .segment-data {
    width: 80px;
    font-size: 12px;
  }

  .pit-seg-desc {
    line-height: normal;
    font-size: 14px;
  }

  .metric-title {
    font-size: 14px;
  }

  canvas.scatter-charts {
    width: 100% !important;
  }

  .segment-outer {
    padding: 10px 5px;
  }

  .progress-bars {
    width: 100%;
  }

  .player-flex {
    display: block;
  }

  /* .baddie-logo {
    width: 200px;
    height: 100px;
    object-fit: cover;
  } */
  .user-section {
    width: 60px;
    font-size: 13px;
    line-height: normal;
  }

  .page-title {
    font-size: 18px;
  }

  .page-desc {
    font-size: 16px;
  }

  .research-text {
    font-size: 22px;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 375px) {
  .bar-chart {
    width: 150px !important;
    height: 140px !important;
  }

  .dougnut-chart {
    width: 150px !important;
    height: 150px !important;
  }

  .scatter-chart {
    width: 150px !important;
    height: 140px !important;
  }

  .line-chart {
    width: 150px !important;
    height: 140px !important;
  }

  .team-over {
    font-size: 14px;
    line-height: normal;
  }

  .promo-code {
    font-size: 18px;
    width: 100% !important;
    margin-top: 20px;
  }

  .input-butns {
    display: block;
  }

  .promo-code {
    width: 100% !important;
  }

  .promo-input {
    width: 100% !important;
  }

  .subscribe-butns {
    width: 100% !important;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 320px) {
  .team-over {
    font-size: 12px;
    line-height: normal;
  }

  .pitcher-tools {
    font-size: 16px;
  }

  .segment {
    font-size: 16px;
    line-height: normal;
  }

  .segment-desc {
    font-size: 12px;
    line-height: normal;
  }

  /* .baddie-logo {
    width: 150px;
    height: 80px;
    object-fit: cover;
  } */
  .b-logo {
    color: red;
    font-size: 40px;
    font-weight: 600;
  }

  .user-section {
    width: 55px;
    font-size: 12px;
    line-height: normal;
  }

  .graph-section {
    width: 100%;
  }

  .promo-code {
    font-size: 16px;
    height: 42px;
  }
  .picks_responive{
    width: 100%;
    height: 500px;
  }
}

.table-container {
  overflow-x: auto;
}

.table {
  width: auto;
  white-space: nowrap;
}

.table .rdt_TableHead {
  position: sticky !important;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.table .rdt_TableHead .rdt_TableCol:nth-child(-n + 2),
.table .rdt_TableBody .rdt_TableRow>div:nth-child(-n + 2) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

/* Ensure proper width for the first column */
.table .rdt_TableHead .rdt_TableCol:nth-child(1) {
  min-width: 100px;
  /* Adjust width if necessary */
}

.table .rdt_TableBody .rdt_TableRow>div:nth-child(1) {
  min-width: 100px;
  /* Adjust width if necessary */
}

.table .rdt_TableHead .rdt_TableCol:nth-child(2) {
  min-width: 50px;
  /* Adjust width if necessary */
}

.table .rdt_TableBody .rdt_TableRow>div:nth-child(2) {
  min-width: 50px;
  /* Adjust width if necessary */
}

.table .rdt_TableHead .rdt_TableCol:nth-child(2) {
  left: 100px;
  /* Adjust width if necessary */
}

.table .rdt_TableBody .rdt_TableRow>div:nth-child(2) {
  left: 100px;
  /* Adjust width if necessary */
}

@media screen and (min-width: 430px) {

  /* Make the first two columns sticky */
  .table .rdt_TableHead .rdt_TableCol:nth-child(-n + 2),
  .table .rdt_TableBody .rdt_TableRow>div:nth-child(-n + 2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  /* Ensure proper width for the first column */
  .table .rdt_TableHead .rdt_TableCol:nth-child(1) {
    min-width: 150px;
    /* Adjust width if necessary */
  }

  .table .rdt_TableBody .rdt_TableRow>div:nth-child(1) {
    min-width: 150px;
    /* Adjust width if necessary */
  }

  .table .rdt_TableHead .rdt_TableCol:nth-child(2) {
    left: 150px;
    /* Adjust width if necessary */
  }

  .table .rdt_TableBody .rdt_TableRow>div:nth-child(2) {
    left: 150px;
    /* Adjust width if necessary */
  }

  /* Adjust other styles as needed */
}

.table1 .rdt_TableHead .rdt_TableCol:nth-child(-n + 1),
.table1 .rdt_TableBody .rdt_TableRow>div:nth-child(-n + 1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

/* Ensure proper width for the first column */
.table1 .rdt_TableHead .rdt_TableCol:nth-child(1) {
  min-width: 70px;
  /* Adjust width if necessary */
}

.table1 .rdt_TableBody .rdt_TableRow>div:nth-child(1) {
  min-width: 70px;
  /* Adjust width if necessary */
}

.table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: #fff;
}

.table .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: #e2dbe3;
}

.table1 .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: #fff;
}

.table1 .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: #e2dbe3;
}

/******************** login css **************/

.bg_image {
  background-image: url(../public/signup-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.inner_bg {
  background: rgba(255, 255, 255, 0.14);
  box-shadow: 0px 0px 38.23143005371094px rgba(255, 255, 255, 0.08) inset;
  border-radius: 30px;
  backdrop-filter: blur(32.26px);
  padding: 50px;
}

.inner_bg input {
  opacity: 0.5;
  background: #593941 !important;
  border: 1px white solid;
  color: #fff !important;
  height: 55px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.09px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
  height: 55px;
}

.inner_bg input::placeholder {
  color: #fff !important;
  font-family: var(--body-font-family);
}

.get_started {
  background: #ff0000 !important;
  height: 50px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 27.21px;
  word-wrap: break-word;
  font-family: "Lato", sans-serif;
}

.custom_label {
  color: white;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24.08px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
}

.main_heading {
  color: white;
  font-size: 35px;
  font-family: Impact;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 59.79px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
}

.dont-have {
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22.68px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
  font-size: 18px;
  margin-bottom: 30px;
}

.have_acc {
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22.68px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
  font-size: 18px;
}

.inner_bg {
  max-width: 42rem;
}

.forgot_passs {
  padding-top: 25px;
  width: 100%;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 15.51px;
  word-wrap: break-word;
}

.forgot_passs a {
  color: white;
  font-size: 25px;
  font-family: Bebas Neue;
  font-weight: 400;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 22.68px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
}

.get_started svg.lucide.lucide-arrow-right.ml-2 {
  display: none;
}

select#countries {
  opacity: 0.5;
  background: #593941 !important;
  border: 1px white solid;
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.09px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
  height: 55px;
}

.outer-name {
  display: flex;
}

.f-name {
  width: 49%;
  margin-right: 1%;
}

.l-name {
  width: 49%;
  margin-left: 1%;
}

.inner_back {
  background: rgba(255, 255, 255, 0.14);
  box-shadow: 0px 0px 38.23143005371094px rgba(255, 255, 255, 0.08) inset;
  border-radius: 30px;
  backdrop-filter: blur(32.26px);
  margin-top: 8%;
  max-width: 65rem;
}

.inner_back .mx-auto.md\:max-w-4xl {
  max-width: 100%;
}

.need_bg {
  background: #593941;
  border: 1px solid #ffffff75;
  padding-top: 10px;
}

.per_month {
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  line-height: 21.69px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
}

.amount {
  color: #ff0000;
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 49.89px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
}

p.starting {
  color: white;
  font-size: 25px;
  font-family: var(--headings-font-family);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22.68px;
  word-wrap: break-word;
  margin-top: 15px;
}

.description {
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  line-height: 23.1px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
  padding-right: 77px;
  min-height: 115px;
}

.Select_plan {
  background: #ff0000 !important;
  height: 55px;
  color: white;
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 27.21px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
}

.out_plan {
  display: grid;
}

.inner_popop {
  background: rgba(255, 255, 255, 0.14);
  box-shadow: 0px 0px 38.23143005371094px rgba(255, 255, 255, 0.08) inset;
  border-radius: 30px;
  backdrop-filter: blur(32.26px);
}

.custom_popup {
  max-width: 40px;
  max-width: 42rem;
  height: 450px;
}

button.inline-flex.h-12.animate-shimmer.items-center.justify-center.subscribe-butns.w-full {
  background: #ff0000 !important;
  height: 55px;
  color: white;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 27.21px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
  margin-top: 30px;
}

.custom_input {
  background: #59394100 !important;
  border: 1px white solid;
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 27.09px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
  height: 55px;
  width: 60%;
}

.promo-code {
  opacity: inherit;
  background: #fff !important;
  border: 1px white solid;
  color: #f00 !important;
  font-size: 17px;
  font-weight: 400;
  line-height: 27.09px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
  height: 55px;
  font-family: var(--headings-font-family);
  width: 40%;
}

.inner_popop .CardField-input-wrapper {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: left, right;
  white-space: nowrap;
  width: 100%;
  height: 50px;
}

.inner_popop .ElementsApp input {
  height: 55px;
}

.inner_popop iframe {
  height: 27px !important;
  opacity: inherit !important;
  background: transparent !important;
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.09px;
  word-wrap: break-word;
  font-family: var(--body-font-family);
}

.CardBrandIcon-container {
  height: 100%;
  position: absolute;
  top: 0;
  width: 2em;
  height: 55px;
}

.CardBrandIcon-container {
  height: 55px !important;
}

.inner_popop .ElementsApp {
  font-size: 14px;
  height: 4.2em;
}

.inner_popop .ElementsApp>* {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 55px;
}

.inner_popop .CardField-input-wrapper {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: left, right;
  white-space: nowrap;
  width: 100%;
  height: 50px;
}

.inner_popop .ElementsApp input {
  height: 55px;
}

.InputContainer .InputElement {
  height: 55px !important;
  color: #fff !important;
}

.inner_popop .InputContainer .InputElement {
  position: absolute;
  top: 0;
  height: 55px;
}

.ElementsApp {
  top: 15px !important;
}

.ElementsApp.is-empty {
  top: 15px;
}

.close {
  background: #1d0e13;
  color: #ff0000;
  padding: 5px;
  border-radius: 7px;
}

/******************** homepage css **************/

:root {
  --body-font-family: "Lato", sans-serif;
  --headings-font-family: "Lato", sans-serif;


}

body {
  background: url("../public/fullbodyimage.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.menubar ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menubar ul a {
  width: auto;
  float: left;
}

.menubar ul a:hover {
  background: red !important;
  color: #fff !important;
}

.menubar ul a span {
  font-family: var(--body-font-family);
  font-weight: 400;
}

.top_header {
  margin: 0 auto;
  padding: 0px 10px;
}

.header_bg {
  background: rgba(25, 26, 42, 0.4);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.top_banner {
  height: 100vh;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  /* background:url('../public/HomeBanner.jpg'); */
}

.top_banner h1 {
  font-size: 120px;
  color: #fff;
  font-family: var(--headings-font-family);
}

.top_banner h1 span {
  color: #ff0000;
}

.top_banner h4 {
  font-size: 30px;
  color: #fff;
  font-family: var(--headings-font-family);
  font-weight: 600;
  letter-spacing: 1.2px;
}

.top_banner p {
  font-size: 20px;
  color: rgb(255 255 255 / 80%);
  font-family: var(--body-font-family);
  font-weight: 400;
  bottom: 24px;
  text-align: center;
  margin: 0 auto;
  right: 0;
  left: 0;
}

.top_banner p span {
  color: #ff0000;
}

.action_btn button {
  background: transparent;
  color: #fff;
  border-left: 1px solid rgb(255 255 255 / 10%);
  border-right: 1px solid rgb(255 255 255 / 10%);
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  border-radius: 0px;
  font-size: 20px;
}

.css-1t8x7v1>.ps-menu-button {
  height: auto !important;
}

.sign-up,
.action_btn button:hover {
  background: #ff0000 !important;
}

.col-6 {
  width: 50%;
  float: left;
}

.section2 {
  float: left;
  width: 100%;
  margin: 50px 0px;
}

.sec2heading {
  font-size: 55px;
  font-family: "Lato", sans-serif !important;

  font-weight: 400;
  line-height: 60px;
}

.section2 p {
  font-family: var(--body-font-family);
  font-weight: 400;
  color: #8e8bbf;
  font-size: 22px;
  margin-top: 40px;
}

.section2 ul {
  float: left;
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
}

.section2 ul li {
  float: left;
  width: 100%;
  padding: 9px;
  border: 1px solid rgb(255 255 255 / 19%);
  margin: 4px 0px;
}

.section2 ul li .batting_stats {
  float: left;
  width: 100%;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  background: #fff;
  padding: 10px 10px;
  font-size: 12px !important;
  line-height: 30px;
}

.section2 ul li h1 {
  font-size: 25px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  font-family: var(--headings-font-family);
  font-weight: 400;
}

.section2 ul li h2 {
  font-size: 30px;
  font-weight: 900;
  text-decoration: none;
  text-align: center;
  font-family: var(--body-font-family);
  margin-top: 10px;
}

.section2 ul li:nth-child(3) h2,
.section2 ul li:nth-child(6) h2 {
  color: #22c55e;
}

.button {
  background: #ff0000;
  color: #fff;
  padding: 15px 15px;
  text-transform: capitalize;
  font-family: "Lato", sans-serif !important;
  border-radius: 0px;
  font-size: 20px;
  border: 1px solid #ff0000;
}

.button:hover {
  border: 1px solid #fff;
  background: transparent;
}

.btn-row {
  margin-top: 30px;
  float: left;
}

.section3 {
  float: left;
  width: 100%;
  margin: 50px 0px;
}

.section3 h1 {
  font-size: 65px;
  font-family: var(--headings-font-family);
  font-weight: 400;
  color: #fff;
  margin: 40px 0px;
}

.graph-row p {
  color: #9f9eae;
  font-size: 22px;
  font-family: var(--body-font-family);
  font-weight: 400;
  line-height: 33.11px;
}

.graph-row:nth-child(odd) {
  flex-direction: row-reverse;
}

.graph-row img {
  width: 100%;
}

.graph-content {
  padding: 50px;
}

.section4 {
  float: left;
  width: 100%;
  margin: 50px 0px;
}

.section4 h2 {
  font-size: 65px;
  font-family: var(--headings-font-family);
  font-weight: 400;
  color: #fff;
  line-height: 50px;
}

.section4 p {
  color: #9f9eae;
  font-size: 22px;
  font-family: var(--body-font-family);
  font-weight: 400;
  line-height: 28px;
}

.bet-row {
  margin-bottom: 40px;
}

.bet-row:last-child {
  margin-bottom: 0px;
}

.bet-mlb-btn {
  border: 1px solid #ffffff;
  background: transparent;
  color: #fff;
  padding: 15px;
  margin-top: 30px;
  font-family: var(--headings-font-family);
  font-size: 25px;
}

.bet-mlb-btn:hover {
  background: red;
  border: 1px solid red;
}

.bets-container {
  width: 75%;
  margin: 40px auto;
}

.bet-content {
  padding: 0px 30px;
  text-transform: lowercase;
  font-size: 20px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.footer {
  width: 100%;
  float: left;
}

.social_links {
  margin: 50px 0px;
}

.social_links li a {
  color: #fff;
  font-size: 22px;
}

.divider {
  border: 0px solid rgb(255 255 255 / 20%);
  height: 1px;
  background: rgb(255 255 255 / 20%);
}

.copyright,
.footer-menu {
  color: #fff;
  font-family: var(--body-font-family);
  font-size: 16px;
  font-weight: 400;
}

.mobile-logo,
.mobile-toggle-btn {
  display: none;
}

.sidebar-mobile {
  display: block;
  position: fixed;
  top: 66px;
  background: #000;
  width: 100%;
  z-index: 99;
}

.sidebar-mobile .mobile-inner {
  margin: 0;
}

.sidebar-mobile a button {
  background: transparent;
  color: #fff;
  text-transform: capitalize;
}

.sidebar-mobile a {
  float: left;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
}

.active a {
  background: red !important;
  color: #fff !important;
}

.mobile-logo {
  margin-left: 0px;
  padding: 10px;
}
/* .skiptranslate.goog-te-gadget span {
  display: none;
} */

select.goog-te-combo {
  background: transparent;
  padding: 10px;
  border: 1px solid;
}

/**************** inner page header ****************/
.top_space {
  padding-top: 80px;
}

div#dropdownInformation {
  background: rgba(255, 255, 255, 0.14);
  background: rgb(231 127 127 / 51%);
  box-shadow: 0px 0px 38.23143005371094px rgba(255, 255, 255, 0.08) inset;

  backdrop-filter: blur(32.26px);
  position: absolute;
  right: 5%;
  top: 100%;
  border-radius: 10%;
  padding: 10px;
  min-width: 230px;
}

.action_btn button {
  background: transparent;
  color: #fff;
  border-left: 1px solid rgb(255 255 255 / 10%);
  border-right: 1px solid rgb(255 255 255 / 10%);
  padding: 20px 10px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif !important;
  border-radius: 0px;
  font-size: 16px;
}

.font-medium.truncate {
  color: white;
  font-size: 16px;
  font-family: var(--body-font-family);
  font-weight: 400;
  word-wrap: break-word;
}

.u_name {
  color: white;
  font-size: 22px;
  font-family: var(--headings-font-family);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22.68px;
  word-wrap: break-word;
}

.reset {
  border-top: 0.8px #ffffff54 solid;
}

.mathup-icon {
  margin-left: auto;
}

.game-page-icon {
  margin: auto;
}

.link_pass li a {
  color: white;
  font-size: 17.88px;
  font-family: Bebas Neue;
  font-weight: 400;
  font-family: var(--headings-font-family);
  line-height: 18.03px;
  word-wrap: break-word;
}

.out_rest {
  border-top: none;
}

.inner_bg input {
  width: 100%;
  background: #59394100 !important;
  border: 1px solid #ffffff40 !important;
  padding: 15px 15px;
  line-height: 0.25rem;
  font-size: 22px;
}

.user_out {
  width: 100% !important;
  padding: 10px 0px !important;
  font-size: 18px !important;
  line-height: 1.25rem !important;
  border: 1px solid #ffffff5c;
}

.user_out:hover {
  width: 100% !important;
  padding: 10px 0px !important;
  font-size: 18px !important;
  line-height: 1.25rem !important;
  border: 1px solid #ffffff5c;
  background: red !important;
}

.out_changpass {
  margin-top: 5%;
}

.pass_reset input {
  word-wrap: break-word;
  background: #593941 !important;
  border: 1px solid #fff;
  font-size: 18px;
  font-weight: 400;
  height: 55px;
  line-height: 27.09px;
  opacity: 0.5;
  padding-left: 0.75rem;
  text-transform: capitalize;
}

.pass_reset input::placholder {
  color: #fff;
  text-transform: capitalize;
}

.plan_pop {
  background: #766662;
  width: 35rem;
  margin: 0 auto;
  border-radius: 35px;
  padding: 30px;
}

.plan_pop h2 {
  word-wrap: break-word;
  color: #fff;
  font-family: Impact;
  font-family: Bebas Neue, sans-serif;
  font-family: var(--headings-font-family);
  font-size: 27.92px;
  font-weight: 400;
  line-height: 29.79px;
  text-transform: uppercase;
}

.chance-precip {
  text-align: center;
  line-height: 1.5;
}

.chance-text {
  text-align: center;
}

.table-header th {
  font-size: 1.35rem;
}

.bullpen_bg {
  background: #d32f2f !important;
}

/********************* responisve start **********************/

@media only screen and (max-width: 1499px) {
  .top_banner h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1864px) {
  .wind-mdh {
    font-size: 40px;
  }

  .main-box {
    height: 300px;
  }

  .wind-name {
    margin: 25px 0px;
  }

  .chance-precip {
    margin-top: 15px;
    line-height: normal;
  }

  .second-box h1 {
    margin-top: 65px;
    font-size: 4rem;
    line-height: 1;
  }

  .slate-mobile {
    width: 100px;
  }

  .wind-speed {
    margin-top: 10px;
  }
}

.mobile-dam {
  display: block;
}

@media only screen and (max-width: 1280px) {
  .action_btn button {
    padding: 20px 0px;
    font-size: 25px;
  }

  .css-1t8x7v1>.ps-menu-button {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .section2 ul {
    grid-template-columns: repeat(2, 1fr);
  }

  .top_banner p {
    width: 97%;
  }

  .graph-content {
    padding: 28px;
  }

  .graph-row p {
    font-size: 20px;
    line-height: 30px;
  }

  .section3,
  .section4 {
    margin: 20px 0px;
  }

  .sec2heading,
  .section3 h1,
  .section4 h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .action_btn button {
    padding: 20px 11px;
    width: auto;
    font-size: 20px;
  }

  .section2 ul li h2 {
    font-size: 22px;
    margin-top: 0px;
  }

  .sec2heading,
  .section3 h1,
  .section4 h2 {
    font-size: 50px;
  }

  .top_banner h1 {
    font-size: 50px;
  }

  .wind-mdh {
    font-size: 16px;
  }

  /* .wind-name {
    margin: 5px 0px -12px;
  } */
  .direction-image {
    width: 43px;
  }

  .slate-box {
    padding: 10px 20px;
  }

  .table-header th {
    font-size: 1rem;
    line-height: normal;
  }
  
}

.email-checkbox {
  width: 20px !important;
}

@media only screen and (max-width: 992px) {

  .menubar,
  .mobile-logo-hide {
    display: none;
  }

  .mobile-logo,
  .mobile-toggle-btn {
    display: block;
  }

  .action_btn button {
    padding: 20px 11px;
    width: auto;
    font-size: 20px;
  }

  .mobile-toggle-btn svg {
    color: #fff;
    font-size: 33px;
  }

  .section2 ul li h1 {
    font-size: 20px;
  }

  .section2 ul li .batting_stats {
    padding: 20px 10px;
  }

  .section2 p {
    font-size: 17px;
    margin-top: 30px;
  }

  .top_banner p {
    font-size: 18px;
  }

  .top_banner h4 {
    font-size: 22px;
  }

  .graph-row p,
  .bet-row p {
    font-size: 18px;
    line-height: 30px;
  }

  .bets-container {
    width: 100%;
    display: block;
    float: left;
  }

  .bet-row img {
    margin: 0 auto;
    width: 100%;
  }

  .graph-row,
  .bet-row {
    display: block;
    width: 100%;
    float: left;
  }

  .graph-row .col-6,
  .bet-row .col-6 {
    display: block;
    width: 100%;
    float: left;
    padding: 20px 0px;
  }

  .section3 h1 {
    margin: 40px 0px 20px;
  }

  .top_header {
    padding: 0px 0px;
  }

  .header_bg {
    background: rgb(25 26 42 / 62%);
  }

  .main-section {
    padding: 10px 10px;
  }

  .no-data-text {
    font-size: 1rem;
  }

  .table-header th {
    font-size: 1rem;
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
  .direction-image {
    width: 40px !important;
  }

  .wind-mdh {
    font-size: 14px;
  }

  .wind-name {
    margin-top: 12px;
  }

  .main-section {
    padding: 10px 10px;
  }

  .no-data-text {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .inner_bg {
    padding: 20px 22px;
  }

  .copyright,
  .footer-menu {
    text-align: center;
    width: 100%;
    display: block;
  }

  .footer-menu {
    margin-top: 20px;
  }

  .baddie-logo {
    width: 96px;
  }

  .sec2heading,
  .section3 h1,
  .section4 h2 {
    font-size: 40px;
    text-align: center;
  }

  .section3 h1 {
    margin: 0px 0px 0px;
  }

  .bets-container {
    margin: 0px 0px;
  }

  .bet-mlb-btn {
    width: 100%;
  }

  .section2,
  .section3,
  .section4 {
    padding: 0px 20px;
  }

  .bets-section {
    width: 100%;
    display: block !important;
    float: left;
  }

  .bets-section .col-6 {
    width: 100%;
    display: block !important;
    float: left;
    text-align: center;
  }

  .top_banner h1 {
    font-size: 40px;
  }

  .slate-box {
    padding: 20px 10px;
  }

  .chance-text {
    text-align: center;
  }
}

@media screen and (max-width: 430px) {
  .carousel .control-dots {
    display: none !important;
  }

  .flex_section {
    display: flex;
    justify-content: space-evenly;
  }

  .inner_section {
    display: flex;
    justify-content: space-around;
  }

  .metric-data {
    font-size: 18px;
  }

  .game-inner-text h1 {
    font-size: 10px;
  }

  .metric-sections {
    display: block;
  }

  .table-outer-section {
    width: 350px;
    overflow-x: scroll;
  }

  .mobile_padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tab-lists {
    justify-content: space-around;
  }

  .weather-text {
    display: flex;
  }

  .weather-text1 {
    width: 20%;
  }

  .weather-text2 {
    width: 60%;
  }

  .weather-text3 {
    width: 20%;
    padding-top: 22px;
  }

  .game-data {
    margin-top: 2rem !important;
    font-size: 35px !important;
  }

  .weather-text h1 {
    font-size: 22px;
  }

  .table-header th {
    font-size: 10px;
    line-height: normal;
    padding: 5px;
  }

  .table-body th,
  td {
    font-size: 10px;
    line-height: normal;
    white-space: nowrap;
  }

  .carlos-tab {
    font-size: 14px;
    line-height: normal;
  }

  .purple-card {
    padding: 10px 20px;
    border-radius: 20px;
  }

  .purple-card h1 {
    font-size: 10px;
    line-height: normal;
    margin-bottom: 0px;
  }

  .second-box h1 {
    font-size: 26px;
  }

  .main-heading {
    font-size: 24px;
  }

  .flex_section h1 {
    font-size: 16px;
  }

  .wind-mdh {
    font-size: 18px;
  }

  .game-temp {
    margin-top: 10px !important;
    font-size: 30px;
  }

  .bottom-boxes {
    gap: 0px;
  }

  .main-box {
    margin-top: 0px;
  }

  .second-box {
    height: 95px;
  }

  .park-heading {
    font-size: 24px;
  }

  .upper-text {
    font-size: 1.2rem;
  }

  .tab-section {
    padding-left: 0px;
    padding-right: 0px;
  }

  .carousel-tab {
    padding: 0px;
  }

  .wind-mdh {
    font-size: 16px;
  }

  .direction {
    margin-top: 5px;
  }

  .weather-title {
    margin-bottom: 0px;
  }

  .chance-precip {
    margin-top: -7px;
  }

  .border-section {
    border-left-width: 3px;
  }

  .input-butns {
    display: block;
  }

  .promo-input {
    width: 100% !important;
  }

  .promo-code {
    width: 100% !important;
  }

}

@media only screen and (max-width: 480px) {
  .carousel .control-dots {
    display: none !important;
  }

  .input-butns {
    display: block;
  }

  .direction {
    margin-top: 5px;
  }

  .weather-title {
    margin-bottom: 0px;
  }

  .promo-input {
    width: 100% !important;
  }

  .promo-code {
    width: 100% !important;
    margin-top: 20px;
  }

  .amount {
    font-size: 30px;
    line-height: 45.89px;
  }

  .Select_plan {
    height: 39px;
    font-size: 20px;
  }

  .main_heading {
    font-size: 45.92px;
  }

  .inn_plans {
    margin-top: 25%;
  }

  .out_changpass {
    margin-top: 15%;
  }

  .plan_pop {
    width: 20rem;
    padding: 10px;
    margin-top: 35%;
  }

  .park_value {
    margin-top: 18px;
  }

  .mobile-dam {
    display: block;
  }

  .table-outer-section {
    width: 340px;
    overflow-x: scroll;
  }

  .border-section {
    border-left-width: 3px;
  }

  .bulllphen_tb th {
    font-size: 10px;
    font-style: italic;
    color: #fff;
  }

  .bullpen_bg {
    background: #d32f2f !important;
    padding: 1rem .2rem;
  }
  .description {
    padding-right: 0px;
}
}

@media screen and (max-width: 375px) {
  .table-outer-section {
    width: 300px;
    overflow-x: scroll;
  }

  .border-section {
    border-left-width: 3px;
  }
}

@media screen and (max-width: 320px) {
  .table-outer-section {
    width: 250px;
    overflow-x: scroll;
  }

  .border-section {
    border-left-width: 3px;
  }
}

.back_butn {
  background: #000 !important;
  height: 55px;
  color: white;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 27.21px;
  word-wrap: break-word;
  font-family: var(--headings-font-family);
  font-family: "Lato", sans-serif;

}

.InputContainer .InputElement {
  position: absolute;
  top: 4px !important;
}

.carousel.carousel-slider .control-arrow {
  height: 50px;
  top: 270px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background: black !important;
}

.carousel .carousel-status {
  display: none;
}

.game-data {
  font-size: 4.5rem;
}

.carousel .control-dots {
  bottom: -15px !important;
}

.carousel .control-dots li {
  background: black !important;
}

input.__PrivateStripeElement-input {
  opacity: 0.5 !important;
  background: #593941 !important;
  border: 1px white solid !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 27.09px !important;
  word-wrap: break-word !important;
  font-family: var(--body-font-family) !important;
  height: 55px !important;
  width: 60% !important;
}

input.InputElement.is-complete.Input {
  color: white !important;
  font-size: 20px !important;
  width: 220px !important;
  height: 19px !important;
}

input.InputElement.is-empty.Input.Input--empty {
  color: white !important;
  font-size: 20px !important;
  width: 220px !important;
  height: 19px !important;
}

.inner_popop iframe input::placeholder {
  color: #fff !important;
}

.py-5.StripeElement.StripeElement--empty {
  padding: 10px !important;
  border: 1px solid white;
  border-radius: 5px;
}

.py-5.StripeElement.StripeElement--invalid,
.py-5.StripeElement.StripeElement--focus {
  padding: 10px !important;
  border: 1px solid white;
  border-radius: 5px;
}

.ElementsApp .InputElement.is-invalid {
  color: #eb1c26;
  font-size: 20px !important;
  width: 220px !important;
  height: 19px !important;
}

.CardField--ltr .CardField-input-wrapper {
  left: 2em !important;
  font-size: 23px !important;
  height: 50px !important;
}

/* .weather-title {
  margin-bottom: -17px;
} */


/* Add this CSS to your stylesheet */
.table-container {
  overflow-x: auto;
  width: 100%;
}

.table-container table {
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}

.sticky-col-weather {
  position: sticky;
  left: 0;
  background: #40ecd9;
  /* Ensure background is same as the table */
  z-index: 1;
  /* Ensure it stays on top */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow to the right for better visibility */
}

.sticky-col {
  position: sticky;
  left: 0;
  background: white;
  /* Ensure background is same as the table */
  z-index: 1;
  /* Ensure it stays on top */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow to the right for better visibility */
}

.sticky-header {
  position: sticky;
  left: 0;
  background: white;
  /* Ensure background is same as the table */
  z-index: 2;
  /* Higher than .sticky-col to stay on top */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow to the right for better visibility */
}

.tab-lists li {
  cursor: pointer;
}

.email_notify {
  color: white;
  font-size: 17.88px;
  font-family: Bebas Neue;
  font-weight: 400;
  font-family: var(--headings-font-family);
  line-height: 18.03px;
  word-wrap: break-word;
}

.heading_home {
  font-family: "Lato", sans-serif !important;
  font-size: 25px;
  font-weight: 600;

}

.heading_link {
  font-family: "Lato", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
}

.heading_bet {
  font-family: "Lato", sans-serif !important;
  font-size: 35px !important;
  font-weight: 600;
  text-transform: capitalize;
}

.heading_bet_one {
  font-family: "Lato", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400;
  text-transform: capitalize;
}